import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { Billing } from "../../../api/billings/billings";
import { Button, useDisclosure } from "@nextui-org/react";
import { FaExpand } from "react-icons/fa6";

export function BillingsPage() {
  const [billings, setBillings] = useState<Billing[]>([]);

  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.billings.list()
      .then(data => {
        setBillings(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке платежей!");
      });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2 p-2 md:p-4">
        <div className="flex flex-row gap-2 items-center">
          <span className="font-bold text-2xl">Платежи</span>
          <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены все платежи, связанные с Вами.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Сумма",
                key: "amount",
                type: ColumnType.Number,
                sortable: true
              }, {
                label: "Провайдер",
                key: "provider",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Статус",
                key: "paid",
                type: ColumnType.Boolean,
                sortable: true
              }, {
                label: "Плательщик",
                key: "payer",
                type: ColumnType.Renter,
                sortable: true
              }, {
                label: "Дата",
                key: "date",
                type: ColumnType.Date,
                sortable: true
              }
            ]}
            data={billings}
          />
        </div>
      </div>
    </>
  )
}