import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { Renter } from "../../../api/renters/renters";
import { useDisclosure, Button } from "@nextui-org/react";
import { FaExpand } from "react-icons/fa6";

export function RentersPage() {
  const [renters, setRenters] = useState<Renter[]>([]);
  
  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.renters.list()
      .then(data => {
        setRenters(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке компаний!");
      });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2 p-2 md:p-4">
        <div className="flex flex-row gap-2 items-center">
          <span className="font-bold text-2xl">Компании</span>
          <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены все компании, связанные с Вами.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Название",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "ОРГН",
                key: "orgn",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Номер телефона",
                key: "phone_number",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Адрес почты",
                key: "email",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Владелец",
                key: "holder",
                type: ColumnType.User,
                sortable: true
              }
            ]}
            data={renters}
          />
        </div>
      </div>
    </>
  )
}