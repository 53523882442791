import React, { useState, useEffect, useMemo, ReactNode } from "react";
import { getNormalizedDate, getNormalizedDateTime, isActionsColumn } from "../../../utils";
import { Accordion, AccordionItem, Checkbox, Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Button, useDisclosure } from "@nextui-org/react";
import { UserChip } from "../chips/userChip";
import { RenterChip } from "../chips/renterChip";
import { FaCopy } from "react-icons/fa6";
import { OfficeTypeChip } from "../chips/officeTypeChip";
import { LocationChip } from "../chips/locationChip";
import { FullScreenModal } from "./fullScreenModal";
import { CategoryChip } from "../chips/categoryChip";
import { OfficeChip } from "../chips/officeChip";
import CopyToClipboard from "react-copy-to-clipboard";

export enum ColumnType {
  Date = "date",
  DateTime = "datetime",
  String = "string",
  Number = "number",
  Boolean = "boolean",
  User = "user",
  Renter = "renter",
  OfficeType = "office_type",
  Location = "location",
  Category = "category",
  Office = "office",
  Actions = "actions",
  Custom = "custom"
}

export interface TableAction {
  icon: ReactNode;
  onClick: (value: any, row: any) => void;
}

export interface BaseColumn {
  key: string;
  label: string;
  sortable?: boolean;
  render?: (value: any, row: any) => any;
  type?: ColumnType;
}

export interface ActionsColumn extends BaseColumn {
  type: ColumnType.Actions,
  actions: TableAction[]
}

export type Column = BaseColumn | ActionsColumn;

interface TableBuilderProps {
  rowsPerPage?: number;
  columns: Column[];
  data: any[];
  isFullScreen?: boolean;
  fullscreenDisclosure?: ReturnType<typeof useDisclosure>
  displayOptions?: boolean;
  displaySearch?: boolean;
  removeWrapper?: boolean;
}

type SortDescriptor = {
  column: string;
  direction: 'ascending' | 'descending';
};

export function TableBuilder({ rowsPerPage = 10, columns, data, isFullScreen = false, fullscreenDisclosure = useDisclosure(), displayOptions = true, displaySearch = true, removeWrapper = false }: TableBuilderProps) {
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<any[]>([]);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({ column: "id", direction: "ascending" });
  const [pages, setPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedColumns, setSelectedColumns] = useState(columns.map(column => column.key));
  const [exportColumns, setExportColumns] = useState<string[]>(columns.map(column => column.key).filter(key => key !== 'action'));

  useEffect(() => {
    if (data[0] && !data[0].id) {
      data = data.map((item, index) => {
        item.id = index;
        return item;
      });
    }

    setTableData(data.sort((a, b) => (a.id < b.id ? 1 : -1)));
    setPages(Math.ceil(data.length / rowsPerPage));
  }, [data, rowsPerPage]);

  const onSortChange = (e: SortDescriptor) => {
    setSortDescriptor(e);
    const sorted = sort(tableData, e);
    setTableData(sorted);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handleColumnChange = (key: string) => {
    setSelectedColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key]
    );
  };

  const handleExportColumnChange = (key: string) => {
    setExportColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key]
    );
  };

  const filterData = (data: any[], query: string, columns: string[]) => {
    if (!query) return data;
    return data.filter((row) =>
      columns.some((column) => row[column]?.toString().toLowerCase().includes(query.toLowerCase()))
    );
  };

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filterData(tableData, searchQuery, selectedColumns).slice(start, end);
  }, [page, rowsPerPage, tableData, searchQuery, selectedColumns]);

  function sort<T extends T[]>(items: T[], sortDescriptor: SortDescriptor): T[] {
    const { column, direction } = sortDescriptor;

    if (items.length === 0 || !(column in items[0])) return items;

    return [...items].sort((a, b) => {
      const aValue = a[column as keyof T];
      const bValue = b[column as keyof T];

      if (aValue === bValue) return 0;

      const order = direction === 'ascending' ? 1 : -1;
      return (aValue > bValue ? 1 : -1) * order;
    });
  }

  const exportToCSV = () => {
    const headers = exportColumns.map(colKey => {
      const column = columns.find(col => col.key === colKey);
      return column?.label || colKey;
    });
    const csvRows = [
      headers.join(","),
      ...tableData.map(row => exportColumns.map(colKey => row[colKey]).join(","))
    ];
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {!isFullScreen && (
        <>
          <FullScreenModal disclosure={fullscreenDisclosure}>
            <TableBuilder
              rowsPerPage={rowsPerPage}
              columns={columns}
              data={data}
              isFullScreen={true}
              displayOptions={displayOptions}
              displaySearch={displaySearch}
            />
          </FullScreenModal>
        </>
      )}

      <div className="flex flex-col gap-2 mb-2">
        {displaySearch && (
          <Input
            type="text"
            placeholder="Поиск"
            variant="bordered"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
        {displayOptions && (
          <Accordion>
            <AccordionItem title="Опции">
              <div className="flex flex-col gap-2 mb-8">
                <span>Включать результаты поиска по столбцам:</span>
                <div className="flex flex-row gap-4">
                  {columns.map((column) => (
                    column.key !== 'action' && (
                      <label key={column.key}>
                        <Checkbox
                          defaultSelected={selectedColumns.includes(column.key)}
                          onChange={() => handleColumnChange(column.key)}
                          size="sm"
                        />
                        {column.label}
                      </label>
                    )
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <div className="flex flex-col gap-2">
                  <span>Экспортировать данные в CSV:</span>
                  <div className="flex flex-row gap-4">
                    {columns.map((column) => (
                      column.key !== 'action' && (
                        <label key={column.key}>
                          <Checkbox
                            defaultSelected={exportColumns.includes(column.key)}
                            onChange={() => handleExportColumnChange(column.key)}
                            size="sm"
                          />
                          {column.label}
                        </label>
                      )
                    ))}
                  </div>
                  <Button onClick={exportToCSV} className="max-w-fit mt-4">Экспорт в CSV</Button>
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        )}
      </div>
      <Table
        isStriped
        isHeaderSticky
        removeWrapper={removeWrapper}
        onSortChange={onSortChange as any}
        sortDescriptor={sortDescriptor}
        bottomContent={
          <>
            {pages > 1 && (
              <div className="flex w-full justify-center">
                <Pagination
                  isCompact
                  showControls
                  color="default"
                  variant="flat"
                  page={page}
                  total={pages}
                  onChange={(page) => setPage(page)}
                />
              </div>
            )}
          </>
        }>
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key} allowsSorting={column.sortable || false}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={items} emptyContent={"Нет данных."}>
          {(item) => (
            <TableRow key={(item as any).id}>
              {columns.map((column) => (
                <TableCell key={column.key}>
                  {renderCell(item, column)}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

function CopyableElement({ children }: { children: any }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      {children}
      {(String(children).trim() == "" ? '' :
        <CopyToClipboard text={children}>
          <FaCopy
            className="text-foreground-400 hover:text-foreground-500 cursor-pointer transition-all"
          />
        </CopyToClipboard>
      )}
    </div>
  );
}

function ActionsElement({ actions, value, row }: { actions: TableAction[], value: any, row: any }) {
  return (
    <div className="flex flex-row gap-2 items-center w-full">
      {actions.map(action => (
        <Button size="sm" variant="flat" onClick={() => action.onClick(value, row)}>
          {action.icon}
        </Button>
      ))}
    </div>
  )
}

export function renderCell(row: any, column: Column) {
  switch (column.type) {
    case ColumnType.Date:
      return <CopyableElement>{getNormalizedDate(row[column.key])}</CopyableElement>;

    case ColumnType.DateTime:
      return <CopyableElement>{getNormalizedDateTime(row[column.key])}</CopyableElement>;

    case ColumnType.Number:
      return <CopyableElement>{row[column.key].toString()}</CopyableElement>;

    case ColumnType.Boolean:
      return <CopyableElement>{row[column.key] ? "да" : "нет"}</CopyableElement>;

    case ColumnType.String:
      return <CopyableElement>{row[column.key]}</CopyableElement>;

    case ColumnType.User:
      return <UserChip userId={row[column.key]} />;

    case ColumnType.Renter:
      return <RenterChip renterId={row[column.key]} />;

    case ColumnType.OfficeType:
      return <OfficeTypeChip officeTypeId={row[column.key]} />;

    case ColumnType.Location:
      return <LocationChip locationId={row[column.key]} />;

    case ColumnType.Category:
      return <CategoryChip categoryId={row[column.key]} />;

    case ColumnType.Office:
      return <OfficeChip officeId={row[column.key]} />;

    case ColumnType.Actions:
      return <ActionsElement actions={isActionsColumn(column) ? column.actions : []} row={row} value={row[column.key]} />;

    case ColumnType.Custom:
      return column.render!(row[column.key], row);

    default:
      return "н/д";
  }
}
