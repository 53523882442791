import { Controller, RegisterOptions, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { Checkbox, Input } from "@nextui-org/react";
import { UserRoleHandbook } from "../handbooks/userRoleHandbook";
import { RenterHandbook } from "../handbooks/renterHandbook";
import { CategoryHandbook } from "../handbooks/categoryHandbook";
import { OfficeHandbook } from "../handbooks/officeHandbook";
import { ModalError } from "../modals/formModalBuilder";

export type InputType = "text" | "password" | "email" | "datetime-local" | "user-role" | "renter" | "category" | "office" | "boolean";

export interface InputField {
  type: InputType;
  name: string;
  label: string;
  placeholder: string;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  isRequired?: boolean;
  pattern?: any;
  patternMessage?: string;
}

export interface DefaultValues {
  [key: string]: string;
}

export function FormBuilder({ formHook, fields, defaultValues, modalErrors }: { formHook: ReturnType<typeof useForm>, fields: InputField[], defaultValues?: DefaultValues, modalErrors: ModalError[] }) {
  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((key) => {
      formHook.setValue(key, defaultValues?.[key] || "");
    });
  }, [defaultValues]);

  useEffect(() => {
    modalErrors.forEach(modalError => {
      formHook.setError(modalError.field, { type: "manual", message: modalError.message });
    });
  }, [modalErrors, formHook.clearErrors, formHook.setError]);

  const getRegisterProps = (field: InputField) => {
    const props: RegisterOptions = {};

    if (field.isRequired) {
      props.required = "Это поле обязательно для заполнения";
    }

    if (field.pattern) {
      props.pattern = { value: field.pattern, message: field.patternMessage || "" };
    }

    return props;
  }

  const getBaseInputProps = (field: InputField) => {
    const props: any = Object.assign({}, formHook.register(field.name, getRegisterProps(field)));

    props.type = field.type;
    props.name = field.name;
    props.isInvalid = !!formHook.formState.errors[field.name];
    props.errorMessage = formHook.formState.errors[field.name]?.message;
    props.placeholder = field.placeholder;
    props.label = field.label;
    props.defaultValue = String(defaultValues?.[field.name] || "");
    props.isRequired = field.isRequired;

    return props;
  }

  const getHandbookProps = (field: InputField) => {
    // TODO write universal types for handbook props
    const props: any = {};

    props.register = formHook.register(field.name, getRegisterProps(field));
    props.isInvalid = !!formHook.formState.errors[field.name];
    props.errorMessage = formHook.formState.errors[field.name]?.message?.toString() || "";
    props.defaultSelectedKey = String(defaultValues?.[field.name] || "");

    return props;
  }

  const renderInput = (field: InputField) => {
    switch (field.type) {
      case 'text':
      case 'password':
      case 'email':
      case 'datetime-local':
        console.log(field.type);
        return (
          <Input
            {...getBaseInputProps(field)}
            variant="bordered"
          />
        );

      case 'boolean':
        return (
          <Controller
            control={formHook.control}
            name={field.name}
            render={({ field: controllerField }) => (
              <Checkbox
                {...controllerField}
                isSelected={controllerField.value}
                onChange={controllerField.onChange}
                isInvalid={!!formHook.formState.errors[field.name]}
              >
                {field.label}
              </Checkbox>
            )}
          />
        );

      case 'user-role':
        return <UserRoleHandbook
          {...getHandbookProps(field)}
        />;

      case 'renter':
        return <RenterHandbook
          {...getHandbookProps(field)}
        />;

      case 'category':
        return <CategoryHandbook
          {...getHandbookProps(field)}
        />;

      case 'office':
        return <OfficeHandbook
          {...getHandbookProps(field)}
        />;
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <div key={index} className="mb-4 block">
          {renderInput(field)}
        </div>
      ))}
    </>
  );
}