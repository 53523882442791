import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../loadingSpinner";
import { PageBuilder, ViewPage } from "./pageBuilder";
import { Column, ColumnType } from "../tables/tableBuilder";
import { User } from "../../../api/auth/auth";
import { getIdFromRenterHandbook } from "../../../utils/getIdFromHandbook";
import { InputField } from "../forms/formBuilder";

interface IUserEditForm {
  username?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  phone_number?: string;
  renter?: number;
  telegram?: number;
}

const editUserFields: InputField[] = [
  { label: "Имя пользователя", name: "username", placeholder: "Логин", type: "text", isRequired: true },
  { label: "Имя", name: "first_name", placeholder: "Виктор", type: "text", isRequired: true },
  { label: "Отчество", name: "middle_name", placeholder: "Геннадьевич", type: "text", isRequired: false },
  { label: "Фамилия", name: "last_name", placeholder: "Логинов", type: "text", isRequired: true },
  { label: "Номер телефона", name: "phone_number", placeholder: "+7999...", type: "text", isRequired: true },
  { label: "Арендатор", name: "renter", placeholder: "0", type: "renter", isRequired: false }
];

const viewUserColumns: Column[] = [
  {
    label: "Имя пользователя",
    key: "username",
    type: ColumnType.String
  },
  {
    label: "ФИО",
    key: "names",
    type: ColumnType.Custom,
    render(_value, row: User) {
      return (<span>{row.first_name}{row.middle_name ? ` ${row.middle_name} ` : ''}{row.last_name}</span>);
    },
  },
  {
    label: "Роль",
    key: "role",
    type: ColumnType.String
  },
  {
    label: "Номер телефона",
    key: "phone_number",
    type: ColumnType.String
  },
  {
    label: "Telegram",
    key: "telegram",
    type: ColumnType.Custom,
    render(value, _row) {
      return (<span>{value == 0 || value == null ? "Не привязан" : "Привязан"}</span>);
    },
  }
];

export function UserViewPage() {
  const { id } = useParams();
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  const onSubmit = async (data: IUserEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
    const renter = await getIdFromRenterHandbook(String(data.renter));

    api.users.update(user!.id, {
      username: data.username,
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      role: Number(1),
      renter: Number(renter)
    })
      .then(_data => {
        toast.success("Успешно отредактировано!");
        resolve(true);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при редактировании пользователя!");
        resolve(false);
      });
    });
  };

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
    api.users.delete(user!.id)
      .then(_data => {
        navigate("/dashboard/users/");
        resolve(true);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при удалении пользователя!");
        resolve(false);
      });
    });
  };

  useEffect(() => {
    api.users.get(Number(id))
      .then(response => {
        setUser(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке данных!");
      });
  }, [id]);

  return (
    <>
      {!user && (
        <LoadingSpinner />
      )}
      {user && (
        <PageBuilder
          title="Пользователь"
          fields={editUserFields}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isSensitiveData={true}
          submitButtonText="Сохранить"
          viewEntityComponent={<ViewPage item={user} columns={viewUserColumns} />}
          defaultValues={{
            username: user?.username,
            first_name: user?.first_name,
            middle_name: user?.middle_name,
            last_name: user?.last_name,
            phone_number: user?.phone_number,
            renter: String(user?.renter)
          }} />
      )}
    </>
  );
}
