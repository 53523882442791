import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { DefaultValues, FormBuilder, InputField } from "../forms/formBuilder";
import { ConfirmEditModal } from "./service/confirmEditModal";
import { ConfirmDeleteModal } from "./service/confirmDeleteModal";

export interface ModalError {
  field: string;
  message: string;
}

export interface ModalBuilderProps {
  title: string;
  fields: InputField[];
  defaultValues?: DefaultValues;
  isOpen: boolean;
  displayDeleteButton?: boolean;
  submitButtonText?: string;
  deleteButtonText?: string;
  isSensitiveData: boolean;
  modalErrors?: ModalError[];
  onSubmit: (data: any) => Promise<Boolean>;
  onDelete?: () => Promise<Boolean>;
  onOpenChange: (open: boolean) => void;
}

export function FormModalBuilder({
  title,
  fields,
  defaultValues,
  isOpen,
  submitButtonText = "Создать",
  deleteButtonText = "Удалить",
  isSensitiveData,
  modalErrors = [],
  onSubmit,
  onDelete,
  onOpenChange,
}: ModalBuilderProps) {
  const formHook = useForm({ defaultValues});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const editSensitiveDisclosure = useDisclosure();
  const deleleleSensetiveDisclosure = useDisclosure();

  useEffect(() => {
    setLoading(false);
  }, [isOpen]);

  const submit = (data: any) => {
    if(isSensitiveData) {
      setData(data);
      editSensitiveDisclosure.onOpen();
    } else {
      onSubmit(data);
    }

    setLoading(true);
  };

  const del = () => {
    if(isSensitiveData) {
      deleleleSensetiveDisclosure.onOpen();
    } else {
      if(onDelete) onDelete();
    }
  };

  const onSensitiveSubmit = (data: any) => {
    setLoading(true);
    onSubmit(data).then(_result => {
      setLoading(false);
    });
  }

  const onSensitiveDelete = () => {
    if (onDelete) {
      setLoading(true);
      onDelete().then(_result => {
        setLoading(false);
      });
    }
  }

  return (
    <>
      <ConfirmEditModal isOpen={editSensitiveDisclosure.isOpen} onOpenChange={editSensitiveDisclosure.onOpenChange} callback={onSensitiveSubmit} data={data} />
      <ConfirmDeleteModal isOpen={deleleleSensetiveDisclosure.isOpen} onOpenChange={deleleleSensetiveDisclosure.onOpenChange} callback={onSensitiveDelete} />

      <Modal isOpen={isOpen} onOpenChange={onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>{title}</ModalHeader>
              <form onSubmit={formHook.handleSubmit(submit)}>
                <ModalBody>
                  <FormBuilder modalErrors={modalErrors} formHook={formHook} fields={fields} defaultValues={defaultValues} />
                </ModalBody>
                <ModalFooter>
                  <div className="flex flex-grow flex-row gap-2">
                    <Button color="default" onClick={onClose} variant="flat">Закрыть</Button>
                  </div>
                  {onDelete && (
                    <Button color="danger" onClick={del} variant="flat">{deleteButtonText}</Button>
                  )}
                  <Button color="success" type="submit" variant="flat" isLoading={loading}>{submitButtonText}</Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}