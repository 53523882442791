import { useNavigate } from "react-router-dom";
import { ColumnType, TableBuilder } from "../../components/tables/tableBuilder";
import { OfficeLocation } from "../../../api/locations/locations";
import { FaDoorClosed, FaExpand } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { Button, useDisclosure } from "@nextui-org/react";

export function LocationsPage() {
  const [locations, setLocations] = useState<OfficeLocation[]>([]);
  const navigate = useNavigate();

  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.locations.list()
      .then(data => {
        setLocations(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке локаций!");
      });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2 p-2 md:p-4">
        <div className="flex flex-row gap-2 items-center">
          <span className="font-bold text-2xl">Локации</span>
          <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены доступные вам локации.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Название",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Адрес",
                key: "address",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Город",
                key: "city",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Станция метро",
                key: "metro_station",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Район",
                key: "district",
                type: ColumnType.String,
                sortable: true
              }, {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaDoorClosed />,
                    onClick: (_value, row) => { navigate(`/dashboard/locations/${row.id}/offices`) }
                  }
                ]
              }              
            ]}
            data={locations}
          />
        </div>
      </div>
    </>
  )
}