import { Button, useDisclosure, Tabs, Tab } from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ConfirmDeleteModal } from "../modals/service/confirmDeleteModal";
import { ConfirmEditModal } from "../modals/service/confirmEditModal";
import { Column, renderCell } from "../tables/tableBuilder";
import { ModalBuilderProps } from "../modals/formModalBuilder";
import { FormBuilder } from "../forms/formBuilder";

export interface PageBuilderProps extends Omit<ModalBuilderProps, 'isOpen' | 'onOpenChange'> {
  viewEntityComponent: any;
}

export function PageBuilder({
  title,
  fields,
  defaultValues,
  isSensitiveData,
  submitButtonText = "Создать",
  onSubmit,
  onDelete,
  viewEntityComponent
}: PageBuilderProps) {
  const formHook = useForm({ defaultValues});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const editSensitiveDisclosure = useDisclosure();
  const deleleleSensetiveDisclosure = useDisclosure();

  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((key) => {
      formHook.setValue(key, defaultValues?.[key] || "");
    });
  }, [defaultValues]);

  const submit = (data: any) => {
    if (isSensitiveData) {
      setData(data);
      editSensitiveDisclosure.onOpen();
    } else {
      onSensitiveSubmit(data);
    }
  };

  const del = () => {
    if (isSensitiveData) {
      deleleleSensetiveDisclosure.onOpen();
    } else {
      onSensitiveDelete();
    }
  };

  const onSensitiveSubmit = (data: any) => {
    setLoading(true);
    onSubmit(data).then(_result => {
      setLoading(false);
    });
  }

  const onSensitiveDelete = () => {
    if (onDelete) {
      setLoading(true);
      onDelete().then(_result => {
        setLoading(false);
      });
    }
  }

  return (
    <>
      <ConfirmEditModal isOpen={editSensitiveDisclosure.isOpen} onOpenChange={editSensitiveDisclosure.onOpenChange} callback={onSensitiveSubmit} data={data} />
      <ConfirmDeleteModal isOpen={deleleleSensetiveDisclosure.isOpen} onOpenChange={deleleleSensetiveDisclosure.onOpenChange} callback={onSensitiveDelete} />

      <div className="p-4">
        <div className="flex flex-col justify-between mb-4">
          <div className="flex flex-col gap-4 mb-4">
            <span className="font-bold text-2xl">{title}</span>
          </div>
          <div className="flex flex-col gap-4">
            <Tabs>
              <Tab title="Просмотр информации">
                { viewEntityComponent }
              </Tab>
              <Tab title="Редактирование">
                <form onSubmit={formHook.handleSubmit(submit)}>
                  <div className="flex flex-col">
                    <FormBuilder modalErrors={[]} formHook={formHook} fields={fields} defaultValues={defaultValues} />
                  </div>
                  <div className="flex flex-row gap-4">
                    {onDelete && (
                      <Button color="danger" onClick={del} variant="flat">Удалить</Button>
                    )}
                    <Button color="success" type="submit" variant="flat" isLoading={loading}>{submitButtonText}</Button>
                  </div>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export function ViewPage({ item, columns }: { item: any | null, columns: Column[] }) {
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col">
          { columns.map(column => {
            return (
              <div className="flex flex-col mb-4 gap-1" key={column.key}>
                <span>{ column.label }</span>
                { /* @ts-ignore */ }
                <span className="text-lg font-medium">{ renderCell(item, column) }</span>
              </div>
            )
          }) }
        </div>
      </div>
    </>
  )
}