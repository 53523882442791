import { useEffect, useState } from "react";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { Office } from "../../../api/officies/officies";
import { Lock } from "../../components/lock/lock";
import { SharedLock as SharedLockType } from "../../../api/sharedLocks/sharedLocks";
import { SharedLock } from "../../components/lock/sharedLock";

export function LocksPage() {
  const [offices, setOffices] = useState<Office[]>([]);
  const [sharedLocks, setSharedLocks] = useState<SharedLockType[]>([]);

  useEffect(() => {
    api.officies.list()
      .then(data => {
        setOffices(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке офисов!");
      });

    api.sharedLocks.get()
      .then(data => {
        setSharedLocks(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке общих замков!");
      });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2 p-2 md:p-4">
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-bold text-2xl">Замки</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены все замки, к которым у Вас есть доступ.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <div className="flex flex-row flex-wrap gap-2 max-w-[720px] p-2 mt-2">
            {sharedLocks.map((lock) => (
              <SharedLock key={lock.id} lock={{ id: lock.id, displayName: lock.display_name }} />
            ))}
            {offices.map((office) => (
              <Lock key={office.id} lock={{ id: office.id, displayName: office.display_name }} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}